<template>
  <v-menu bottom :close-on-content-click="false" offset-y>
    <!-- {{ items }} -->
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bordered
        :content="selectedValue.length"
        class="z-1 select-filter-badge"
        :value="selectedValue.length"
        overlap
      >
        <v-text-field
          append-icon="mdi-menu-down"
          dense
          :class="customClass"
          hide-details
          outlined
          :placeholder="customPlaceholder"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </v-badge>
    </template>

    <v-card>
      <div class="pa-3">
        <div v-for="item in items" :key="item[itemValue]" class="mb-2">
          <v-checkbox
            v-model="selectedValue"
            dense
            hide-details
            :label="item[itemText]"
            :value="item[itemValue]"
            @change="onChangeValue"
          ></v-checkbox>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    customClass: {
      default: ""
    },
    customPlaceholder: {
      default: ""
    },
    items: {
      default: () => [],
      required: true
    },
    itemText: {
      default: "name"
    },
    itemValue: {
      default: "id"
    },
    value: {
      type: Array
    }
  },

  data() {
    return { selectedValue: [] };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    }
  },

  watch: {
    value: {
      handler() {
        this.selectedValue = this.value;
      },
      immediate: true
    }
  },

  methods: {
    onChangeValue(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style scoped>
.z-1 {
  z-index: 1;
}
.select-filter-badge {
  flex: 1;
}
</style>
