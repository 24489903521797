<template>
  <div class="table-wrapper">
    <div class="btn-config-wrapper">
      <button-setting-table v-model="selectedColumn"></button-setting-table>
    </div>

    <v-data-table
      :class="[
        selectedColumn.length ? 'py-2 px-3' : 'pb-13',
        {
          'cursor-wait': orderStatusRequest.value === 'loading-getOrderById'
        }
      ]"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="computedHeader"
      hide-default-footer
      :loading="orderStatusRequest.value === 'loading-getOrders'"
      loading-text="Đang tải dữ liệu"
      :items="orders"
      item-key="id"
    >
      <!-- Start: Body -->
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <!-- Start: Total -->
          <tr class="yellow lighten-5">
            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.code)"></td>
            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.options)"></td>
            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.customer)"></td>
            <td
              v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.assigned_to_user)"
            ></td>
            <td
              v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.shipping_status)"
            ></td>
            <td
              v-if="
                includesColumn(HEADERS_ORDER_TABLE_MAP.payment_methods_label)
              "
              class="font-weight-bold"
            >
              <div class="text-body-2 font-weight-medium">Tổng nợ</div>
              <span>{{
                orderListTotals.total_debt_customer | formatCurrency
              }}</span>
            </td>
            <td
              v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.total)"
              class="font-weight-bold"
            >
              {{ orderListTotals.total_value | formatCurrency }}
            </td>
            <td
              v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.status)"
              class="font-weight-bold"
            ></td>
          </tr>
          <!-- Start: Total -->
          <!-- Start: Items -->
          <tr
            v-for="item in items"
            :key="item.id"
            @click="
              orderStatusRequest.value === 'loading-getOrderById'
                ? null
                : viewDetail(item)
            "
          >
            <td
              v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.code)"
              class="cursor-pointer"
            >
              <div class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-if="item.order_type === 'SALE_AT_STORE'"
                      class="mr-2 font-weight-bold"
                      style="color:#FDD835 !important"
                    >
                      POS
                    </div>

                    <div
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="copyToClipboard(item.code)"
                    >
                      {{ item.code }}
                    </div>
                  </template>
                  <span>Sao chép</span>
                </v-tooltip>
              </div>
            </td>

            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.options)">
              <template v-if="item.details.length > 0">
                <ul
                  class="my-2"
                  v-for="(option, index) in item.details"
                  :key="index"
                >
                  <li>
                    <span>
                      <span class="font-weight-medium"
                        >{{ option.qty.toString().padStart(2, "0") }}
                      </span>
                      <span>{{
                        `${option.option.product_name} - ${option.option.SKU}`
                      }}</span>
                    </span>
                  </li>
                </ul>
              </template>
              <div v-else>Không có sản phẩm nào.</div>
            </td>

            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.customer)">
              <template v-if="item.customer">
                <div>{{ item.customer.name }}</div>

                <div class="text-subtitle-2 font-weight-light">
                  {{ item.customer.phone }}
                </div>
              </template>

              <template v-else>-</template>
            </td>

            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.assigned_to_user)">
              <template v-if="item.assigned_to_user">
                <div>{{ item.assigned_to_user.name }}</div>
              </template>

              <template v-else>-</template>
            </td>

            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.shipping_status)">
              <div
                :class="getShippingStatus(item.shipping_status).textColor"
                class="font-weight-medium"
              >
                {{ getShippingStatus(item.shipping_status).text }}
              </div>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-if="item.shipping_est_date"
                    class="text-body-2 cursor-help"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Hạn:
                    {{
                      humanizeShippingEstTime(
                        item.shipping_est_date,
                        item.shipping_status
                      )
                    }}
                  </div>
                </template>

                <span>{{ formatDateTime(item.shipping_est_date) }}</span>
              </v-tooltip>
            </td>

            <td
              v-if="
                includesColumn(HEADERS_ORDER_TABLE_MAP.payment_methods_label)
              "
              style="width: 150px"
            >
              <div>
                <div
                  :class="getPaymentStatus(item.payment_status).textColor"
                  class="font-weight-medium"
                >
                  {{ getPaymentStatus(item.payment_status).text }}
                </div>

                <div v-if="item.khachhang_con_no" class="text-body-2">
                  <span
                    v-if="item.khachhang_con_no < 0"
                    class="blue--text text--darken-3"
                  >
                    Nợ khách: {{ item.khachhang_con_no | formatCurrency }}
                  </span>

                  <span v-else>
                    Khách nợ: {{ item.khachhang_con_no | formatCurrency }}
                  </span>
                </div>
              </div>
            </td>

            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.total)">
              {{ item.value | formatCurrency }}
            </td>

            <td v-if="includesColumn(HEADERS_ORDER_TABLE_MAP.status)">
              <v-chip
                v-if="!item.cancel_status"
                class="font-weight-bold"
                :color="getOrderStatus(item.status).color"
                small
                outlined
              >
                {{ getOrderStatus(item.status).text }}
              </v-chip>

              <v-chip
                v-else
                class="font-weight-bold"
                color="red accent-2"
                small
                outlined
              >
                Bị hủy
              </v-chip>
            </td>
          </tr>
          <!-- End: Items -->
        </tbody>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td :colspan="orders.length">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
      <!-- End: Body -->
    </v-data-table>
  </div>
</template>

<script>
import { timeLeftFromCurent } from "@/core/utils";
import { getOrderStatusByValue } from "../../../utils/common";
import {
  HEADERS_ORDER_TABLE_MAP,
  LIST_ORDER_CHANNELS,
  LIST_STATUS_ORDER,
  LIST_STATUS_SHIPPING,
  getPaymentStatus
} from "../../../constant";
import ButtonSettingTable from "./ButtonSettingTable.vue";
import moment from "moment";

export default {
  components: {
    ButtonSettingTable
  },

  data() {
    return {
      HEADERS_ORDER_TABLE_MAP: HEADERS_ORDER_TABLE_MAP,
      orderChannelList: LIST_ORDER_CHANNELS,
      orderStatusList: LIST_STATUS_ORDER,
      LIST_STATUS_SHIPPING: LIST_STATUS_SHIPPING,
      selectedColumn: []
    };
  },

  computed: {
    computedHeader() {
      const ARRAY_HEADERS_ORDER_TABLE = Object.values(
        this.HEADERS_ORDER_TABLE_MAP
      );

      return ARRAY_HEADERS_ORDER_TABLE.filter(column =>
        this.selectedColumn.some(item => item.value === column.value)
      );
    },

    order() {
      return this.$store.getters["ORDER/order"];
    },
    orderListTotals() {
      return this.$store.getters["ORDER/orderListTotals"];
    },
    orders() {
      return this.$store.getters["ORDER/orders"];
    },
    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    }
  },

  methods: {
    calculateOrderTotal(options) {
      return options.reduce((total, item) => {
        const giftTotal = item.gifts.reduce(
          (giftTotal, gift) =>
            giftTotal + gift.qty * (gift.unit_price - gift.rebate_amount),
          0
        );

        return (
          total + item.qty * (item.unit_price - item.rebate_amount) + giftTotal
        );
      }, 0);
    },

    formatDateTime(dateTime) {
      return moment(dateTime).format("DD/MM/YYYY HH:mm");
    },

    getOrderStatus(value) {
      return getOrderStatusByValue(value);
    },

    getShippingStatus(value) {
      return LIST_STATUS_SHIPPING.find(item => item.id === value);
    },

    getPaymentStatus(value) {
      return getPaymentStatus(value);
    },

    humanizeShippingEstTime(dateTime, shippingStatus) {
      const now = new Date();
      const shippingEstDate = new Date(dateTime);

      if (shippingStatus === 5) {
        return `đã giao ${timeLeftFromCurent(dateTime)} trước`;
      } else if (shippingEstDate < now) {
        return `quá ${timeLeftFromCurent(dateTime)}`;
      } else {
        return `còn ${timeLeftFromCurent(dateTime)}`;
      }
    },

    includesColumn(column) {
      return this.computedHeader.includes(column);
    },

    timeLeftFromCurent(dateTime) {
      return timeLeftFromCurent(dateTime);
    },

    async viewDetail(item) {
      await this.$store.dispatch("ORDER/getOrderById", item.id);

      if (this.orderStatusRequest.value === "success-getOrderById") {
        this.$modal.show({
          name: "modal-order"
        });
      }

      // Get order payments
      await this.$store.dispatch("ORDER/getOrderPaymentTransactions", item.id);

      // Get order participant
      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\Order",
          modelId: item.id
        }
      );

      // Get order online payments
      this.$store.dispatch("ORDER/getOrderOnlinePayments", item.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.w-max-content {
  width: max-content;
}
.cursor-help {
  cursor: help;
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}
.table-wrapper {
  position: relative;
}
.btn-config-wrapper {
  position: absolute;
  right: 8px;
  top: 8px;
}
</style>
