var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"btn-config-wrapper"},[_c('button-setting-table',{model:{value:(_vm.selectedColumn),callback:function ($$v) {_vm.selectedColumn=$$v},expression:"selectedColumn"}})],1),_c('v-data-table',{class:[
      _vm.selectedColumn.length ? 'py-2 px-3' : 'pb-13',
      {
        'cursor-wait': _vm.orderStatusRequest.value === 'loading-getOrderById'
      }
    ],attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.computedHeader,"hide-default-footer":"","loading":_vm.orderStatusRequest.value === 'loading-getOrders',"loading-text":"Đang tải dữ liệu","items":_vm.orders,"item-key":"id"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var items = ref.items;
return [(items.length > 0)?_c('tbody',[_c('tr',{staticClass:"yellow lighten-5"},[(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.code))?_c('td'):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.options))?_c('td'):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.customer))?_c('td'):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.assigned_to_user))?_c('td'):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.shipping_status))?_c('td'):_vm._e(),(
              _vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.payment_methods_label)
            )?_c('td',{staticClass:"font-weight-bold"},[_c('div',{staticClass:"text-body-2 font-weight-medium"},[_vm._v("Tổng nợ")]),_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.orderListTotals.total_debt_customer)))])]):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.total))?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.orderListTotals.total_value))+" ")]):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.status))?_c('td',{staticClass:"font-weight-bold"}):_vm._e()]),_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"click":function($event){_vm.orderStatusRequest.value === 'loading-getOrderById'
              ? null
              : _vm.viewDetail(item)}}},[(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.code))?_c('td',{staticClass:"cursor-pointer"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(item.order_type === 'SALE_AT_STORE')?_c('div',{staticClass:"mr-2 font-weight-bold",staticStyle:{"color":"#FDD835 !important"}},[_vm._v(" POS ")]):_vm._e(),_c('div',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1)]):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.options))?_c('td',[(item.details.length > 0)?_vm._l((item.details),function(option,index){return _c('ul',{key:index,staticClass:"my-2"},[_c('li',[_c('span',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(option.qty.toString().padStart(2, "0"))+" ")]),_c('span',[_vm._v(_vm._s(((option.option.product_name) + " - " + (option.option.SKU))))])])])])}):_c('div',[_vm._v("Không có sản phẩm nào.")])],2):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.customer))?_c('td',[(item.customer)?[_c('div',[_vm._v(_vm._s(item.customer.name))]),_c('div',{staticClass:"text-subtitle-2 font-weight-light"},[_vm._v(" "+_vm._s(item.customer.phone)+" ")])]:[_vm._v("-")]],2):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.assigned_to_user))?_c('td',[(item.assigned_to_user)?[_c('div',[_vm._v(_vm._s(item.assigned_to_user.name))])]:[_vm._v("-")]],2):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.shipping_status))?_c('td',[_c('div',{staticClass:"font-weight-medium",class:_vm.getShippingStatus(item.shipping_status).textColor},[_vm._v(" "+_vm._s(_vm.getShippingStatus(item.shipping_status).text)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(item.shipping_est_date)?_c('div',_vm._g(_vm._b({staticClass:"text-body-2 cursor-help"},'div',attrs,false),on),[_vm._v(" Hạn: "+_vm._s(_vm.humanizeShippingEstTime( item.shipping_est_date, item.shipping_status ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.shipping_est_date)))])])],1):_vm._e(),(
              _vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.payment_methods_label)
            )?_c('td',{staticStyle:{"width":"150px"}},[_c('div',[_c('div',{staticClass:"font-weight-medium",class:_vm.getPaymentStatus(item.payment_status).textColor},[_vm._v(" "+_vm._s(_vm.getPaymentStatus(item.payment_status).text)+" ")]),(item.khachhang_con_no)?_c('div',{staticClass:"text-body-2"},[(item.khachhang_con_no < 0)?_c('span',{staticClass:"blue--text text--darken-3"},[_vm._v(" Nợ khách: "+_vm._s(_vm._f("formatCurrency")(item.khachhang_con_no))+" ")]):_c('span',[_vm._v(" Khách nợ: "+_vm._s(_vm._f("formatCurrency")(item.khachhang_con_no))+" ")])]):_vm._e()])]):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.total))?_c('td',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.value))+" ")]):_vm._e(),(_vm.includesColumn(_vm.HEADERS_ORDER_TABLE_MAP.status))?_c('td',[(!item.cancel_status)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.getOrderStatus(item.status).color,"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.getOrderStatus(item.status).text)+" ")]):_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"red accent-2","small":"","outlined":""}},[_vm._v(" Bị hủy ")])],1):_vm._e()])})],2):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":_vm.orders.length}},[_vm._v("Không có dữ liệu")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }