<template>
  <div class="px-5">
    <top-action @exportReportFile="exportReportFile"></top-action>

    <div class="d-flex justify-start mt-4">
      <v-btn
        v-if="countIncomplete.incomplete_me"
        class="ml-3"
        :class="[
          tabs === 'incomplete_me'
            ? 'blue lighten-4 blue--text text--darken-1'
            : ''
        ]"
        depressed
        @click="handleIncompleteMe"
        >Đơn cần hoàn thành của bạn ({{ countIncomplete.incomplete_me }})
      </v-btn>

      <v-btn
        v-if="countIncomplete.incomplete_branch"
        class="ml-3"
        :class="[
          tabs === 'incomplete_branch'
            ? 'blue lighten-4 blue--text text--darken-1'
            : ''
        ]"
        depressed
        @click="handleIncompleteBranch"
        >Đơn cần hoàn thành của đơn vị bạn ({{
          countIncomplete.incomplete_branch
        }})
      </v-btn>
    </div>

    <filter-area
      :assigned-me="assignedMe"
      :delivery-dates="deliveryDates"
      :filter-branches="branches"
      :order-status="orderStatus"
      :payment-status="paymentStatus"
      :quantity-of-selected-in-drawer="quantityOfSelectedInDrawer"
      :search-key="searchKey"
      :search-options="searchOptions"
      :search-type="searchType"
      :shipping-status="shippingStatus"
      :tabs="tabs"
      class="mt-3"
      @openDrawerMoreFilter="openDrawerMoreFilter"
      @updateFilterProperty="updateFilterProperty($event)"
      @updateSearchKey="updateSearchKey($event)"
    ></filter-area>

    <drawer-more-filter
      :created-date="createdDate"
      :debt-filter="debtFilter"
      :debt-value-list="debtValueList"
      :delivered-dates="deliveredDates"
      :employees-close-a-sale="employeesCloseASale"
      :employees-filter="employees"
      :export-dates="exportDates"
      :order-channels="orderChannels"
      :recorded-date="recordedDate"
      :sites="sites"
      :sources="sources"
      :visible="isDrawerVisible"
      @closeDrawer="closeDrawer"
      @onSubmit="handleSaveMoreFilter"
    ></drawer-more-filter>

    <filter-tags
      :assigned-me="assignedMe"
      :created-date="createdDate"
      :debt-filter="debtFilter"
      :debt-value-list="debtValueList"
      :delivered-dates="deliveredDates"
      :delivery-dates="deliveryDates"
      :employees-close-a-sale="employeesCloseASale"
      :employees-filter="employees"
      :export-dates="exportDates"
      :filter-branches="branches"
      :order-channels="orderChannels"
      :order-status="orderStatus"
      :payment-status="paymentStatus"
      :recorded-date="recordedDate"
      :shipping-status="shippingStatus"
      :sites="sites"
      :sources="sources"
      class="mt-3"
      @updateFilterProperty="updateFilterProperty($event)"
    ></filter-tags>

    <table-pagination
      :current-page="currentPage"
      :selected-show-filter-id="itemPerPage"
      class="mt-3"
      @updateCurrentPage="updateCurrentPage($event)"
      @updateItemPerPage="updateItemPerPage($event)"
    ></table-pagination>

    <data-table-items class="mt-3"></data-table-items>
  </div>
</template>

<script>
import {
  exportExcelFile,
  showModalAlertError,
  updateFilterProperty
} from "@/core/composables";
import DataTableItems from "./components/DataTableItems";
import DrawerMoreFilter from "./components/DrawerMoreFilter";
import FilterTags from "./components/FilterTags";
import TablePagination from "./components/TablePagination";

import TopAction from "./components/TopAction";
import FilterArea from "./components/FilterArea";

export default {
  components: {
    FilterArea,
    FilterTags,
    TopAction,
    DataTableItems,
    DrawerMoreFilter,
    TablePagination
  },

  data() {
    return {
      searchKey: undefined,
      searchType: 1,
      searchOptions: [
        { type: "customer_name", label: "Theo tên khách hàng" },
        { type: "customer_phone", label: "Theo SĐT khách hàng" },
        { type: "serial_number", label: "Theo Serials" },
        { type: "MPN", label: "Theo MPN" },
        { type: "SKU", label: "Theo SKU" },
        { type: "code", label: "Theo mã đơn đặt" },
        { type: "hoadonban_code", label: "Theo mã phiếu xuất kho" },
        { type: "transaction_code", label: "Theo mã phiếu thu, chi" }
      ],
      debtFilter: {
        from: null,
        to: null
      },
      tabs: "in_transaction",
      isDrawerVisible: false,
      branches: [],
      deliveryDates: [],
      deliveryDatesType: "",
      orderStatus: [],
      assignedMe: false,
      paymentStatus: [],
      shippingStatus: [],
      orderChannels: [],
      sites: [],
      employees: [],
      employeesCloseASale: [],
      currentPage: 1,
      itemPerPage: 50,
      savedSearchId: 0,
      createdDate: [],
      recordedDate: [],
      debtValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } },
        { id: 4, text: "Nhỏ hơn 0", value: { from: null, to: -0.000001 } }
      ],
      sources: [],
      deliveredDates: [],
      exportDates: []
    };
  },

  async created() {
    const route = this.$route;
    await this.$store.dispatch("ORDER/getFilters");

    await this.getOrders(route.query);
  },

  computed: {
    quantityOfSelectedInDrawer() {
      return (
        (this.orderChannels.length ? 1 : 0) +
        (this.sites.length ? 1 : 0) +
        (this.employees.length ? 1 : 0) +
        (this.employeesCloseASale.length ? 1 : 0) +
        (this.createdDate.length ? 1 : 0) +
        (this.recordedDate.length ? 1 : 0) +
        (this.debtFilter.from === null && this.debtFilter.to === null ? 0 : 1) +
        (this.sources.length ? 1 : 0) +
        (this.deliveredDates.length ? 1 : 0) +
        (this.exportDates.length ? 1 : 0)
      );
    },
    countIncomplete() {
      return this.$store.getters["ORDER/countIncomplete"];
    }
  },

  methods: {
    checkFilterProperty(property) {
      if (!property.length) return undefined;
      return property;
    },

    async exportReportFile(type) {
      const payload = {
        filter: {
          branch_id: this.checkFilterProperty(this.branches),

          sites: this.checkFilterProperty(this.sites),

          assigned_to_id: this.checkFilterProperty(this.employees),

          closing_user_id: this.checkFilterProperty(this.employeesCloseASale),

          status: this.checkFilterProperty(this.orderStatus),

          from_delivery_date: this.deliveryDates
            ? this.deliveryDates[0]
            : undefined,

          to_delivery_date: this.deliveryDates
            ? this.deliveryDates[1]
            : undefined,

          from_date_create: this.createdDate ? this.createdDate[0] : undefined,

          to_date_create: this.createdDate ? this.createdDate[1] : undefined,

          from_date_record: this.recordedDate
            ? this.recordedDate[0]
            : undefined,

          to_date_record: this.recordedDate ? this.recordedDate[1] : undefined,

          cancel_status: this.cancelStatus,

          order_channel: this.checkFilterProperty(this.orderChannels),

          assigned_me: this.assignedMe ? this.assignedMe : undefined,

          payment_status: this.checkFilterProperty(this.paymentStatus),

          shipping_status: this.checkFilterProperty(this.shippingStatus),

          in_transaction: this.tabs === "in_transaction" ? true : undefined,

          incomplete_me: this.tabs === "incomplete_me" ? true : undefined,

          incomplete_branch:
            this.tabs === "incomplete_branch" ? true : undefined,

          debt_customer_from: this.debtFilter.from,

          debt_customer_to: this.debtFilter.to,

          source: this.checkFilterProperty(this.sources),

          from_delivered_date: this.deliveredDates
            ? this.deliveredDates[0]
            : undefined,

          to_delivered_date: this.deliveredDates
            ? this.deliveredDates[1]
            : undefined,

          from_export_date: this.exportDates ? this.exportDates[0] : undefined,

          to_export_date: this.exportDates ? this.exportDates[1] : undefined
        },
        type: type,
        search_key: this.searchOptions[this.searchType].type,
        search: this.searchKey
      };

      if (
        !(
          payload.filter.from_delivery_date ||
          payload.filter.to_delivery_date ||
          payload.filter.from_date_create ||
          payload.filter.to_date_create ||
          payload.filter.from_date_record ||
          payload.filter.to_date_record ||
          payload.filter.from_delivered_date ||
          payload.filter.to_delivered_date ||
          payload.filter.from_export_date ||
          payload.filter.to_export_date
        )
      ) {
        showModalAlertError(this, {
          title: "Áp dụng lọc trong khoảng thời gian cụ thể để xuất báo cáo",
          message:
            "Bạn đang áp dụng lọc toàn thời gian hoặc tùy chỉnh lọc trong khoảng thời gian không hợp lệ. " +
            "Hệ thống có thể bị quá tải hoặc gặp lỗi nếu xuất báo cáo trong khoảng thời gian quá dài. " +
            "Nếu bạn cần xuất một lượng lớn dữ liệu, vui lòng liên hệ với bộ phận kỹ thuật để được hỗ trợ"
        });

        return;
      }
      await exportExcelFile(this, {
        storeName: "ORDER",
        fileName: "don_dat_hang",
        actionStoreName: "exportOrderFile",
        payload: payload
      });
    },

    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    async getOrders(query) {
      if (query.currentPage) {
        // Get current tabs
        this.tabs = query.tabs;

        this.assignedMe = !!query.assignedMe;

        this.branches =
          typeof query.branches === "string"
            ? [parseInt(query.branches)]
            : typeof query.branches === "object"
            ? query.branches.map(item => parseInt(item))
            : [];

        this.paymentStatus =
          typeof query.paymentStatus === "string"
            ? [parseInt(query.paymentStatus)]
            : typeof query.paymentStatus === "object"
            ? query.paymentStatus.map(item => item)
            : [];

        this.shippingStatus =
          typeof query.shippingStatus === "string"
            ? [parseInt(query.shippingStatus)]
            : typeof query.shippingStatus === "object"
            ? query.shippingStatus.map(item => parseInt(item))
            : [];

        this.deliveryDates = query.delivery_date_from
          ? [query.delivery_date_from, query.delivery_date_to]
          : [];

        this.deliveryDatesType = query.deliveryDatesType;

        this.orderChannels =
          typeof query.orderChannels === "string"
            ? [parseInt(query.orderChannels)]
            : typeof query.orderChannels === "object"
            ? query.orderChannels.map(item => parseInt(item))
            : [];

        this.orderStatus =
          typeof query.orderStatus === "string"
            ? [query.orderStatus]
            : typeof query.orderStatus === "object"
            ? query.orderStatus.map(item => parseInt(item))
            : [];

        this.sites =
          typeof query.sites === "string"
            ? [query.sites]
            : typeof query.sites === "object"
            ? query.sites.map(item => item)
            : [];

        this.employees =
          typeof query.employees === "string"
            ? [parseInt(query.employees)]
            : typeof query.employees === "object"
            ? query.employees.map(item => parseInt(item))
            : [];

        this.employeesCloseASale =
          typeof query.employeesCloseASale === "string"
            ? [parseInt(query.employeesCloseASale)]
            : typeof query.employeesCloseASale === "object"
            ? query.employeesCloseASale.map(item => parseInt(item))
            : [];

        this.searchKey = query.searchKey || undefined;

        this.searchType = query.searchKey ? parseInt(query.searchType) : 1;

        this.itemPerPage = parseInt(query.itemPerPage);

        this.currentPage = parseInt(query.currentPage);

        this.savedSearchId = query.savedSearchId
          ? parseInt(query.savedSearchId)
          : undefined;

        this.createdDate = query.created_date_from
          ? [query.created_date_from, query.created_date_to]
          : [];

        this.recordedDate = query.recorded_date_from
          ? [query.recorded_date_from, query.recorded_date_to]
          : [];

        this.debtFilter = {
          from:
            query.debt_customer_from !== undefined
              ? parseFloat(query.debt_customer_from)
              : null,
          to:
            query.debt_customer_to !== undefined
              ? parseFloat(query.debt_customer_to)
              : null
        };

        this.sources =
          typeof query.sources === "string"
            ? [query.sources]
            : typeof query.sources === "object"
            ? query.sources.map(item => item)
            : [];

        this.deliveredDates = query.from_delivered_date
          ? [query.from_delivered_date, query.to_delivered_date]
          : [];

        this.exportDates = query.from_export_date
          ? [query.from_export_date, query.to_export_date]
          : [];

        //Get API
        await this.$store.dispatch("ORDER/getOrders", {
          filter: {
            branch_id: this.checkFilterProperty(this.branches),

            sites: this.checkFilterProperty(this.sites),

            assigned_to_id: this.checkFilterProperty(this.employees),

            closing_user_id: this.checkFilterProperty(this.employeesCloseASale),

            status: this.checkFilterProperty(this.orderStatus),

            from_delivery_date: this.deliveryDates
              ? this.deliveryDates[0]
              : undefined,

            to_delivery_date: this.deliveryDates
              ? this.deliveryDates[1]
              : undefined,

            from_date_create: this.createdDate
              ? this.createdDate[0]
              : undefined,

            to_date_create: this.createdDate ? this.createdDate[1] : undefined,

            from_date_record: this.recordedDate
              ? this.recordedDate[0]
              : undefined,

            to_date_record: this.recordedDate
              ? this.recordedDate[1]
              : undefined,

            cancel_status: this.cancelStatus,

            order_channel: this.checkFilterProperty(this.orderChannels),

            assigned_me: this.assignedMe ? this.assignedMe : undefined,

            payment_status: this.paymentStatus ? this.paymentStatus : undefined,

            shipping_status: this.checkFilterProperty(this.shippingStatus),

            in_transaction: this.tabs === "in_transaction" ? true : undefined,

            incomplete_me: this.tabs === "incomplete_me" ? true : undefined,

            incomplete_branch:
              this.tabs === "incomplete_branch" ? true : undefined,

            debt_customer_from: this.debtFilter.from,

            debt_customer_to: this.debtFilter.to,

            source: this.checkFilterProperty(this.sources),

            from_delivered_date: this.deliveredDates
              ? this.deliveredDates[0]
              : undefined,

            to_delivered_date: this.deliveredDates
              ? this.deliveredDates[1]
              : undefined,

            from_export_date: this.exportDates
              ? this.exportDates[0]
              : undefined,

            to_export_date: this.exportDates ? this.exportDates[1] : undefined
          },

          search_key: this.searchOptions[this.searchType].type,
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    async pushRouter() {
      const currentRoute = this.$router.currentRoute;
      const targetRoute = {
        name: "transaction_orders",
        query: {
          tabs: this.tabs,
          assignedMe: this.assignedMe ? this.assignedMe : undefined,
          branches:
            this.branches && this.branches.length > 0
              ? this.branches
              : undefined,

          paymentStatus:
            this.paymentStatus && this.paymentStatus.length > 0
              ? this.paymentStatus
              : undefined,

          shippingStatus:
            this.shippingStatus && this.shippingStatus.length > 0
              ? this.shippingStatus
              : undefined,

          delivery_date_from: this.deliveryDates
            ? this.deliveryDates[0]
            : undefined,

          delivery_date_to: this.deliveryDates
            ? this.deliveryDates[1]
            : undefined,

          deliveryDatesType: this.deliveryDates[0]
            ? this.deliveryDatesType
            : undefined,

          orderChannels:
            this.orderChannels && this.orderChannels.length > 0
              ? this.orderChannels
              : undefined,

          orderStatus:
            this.orderStatus && this.orderStatus.length > 0
              ? this.orderStatus
              : undefined,

          sites: this.sites && this.sites.length > 0 ? this.sites : undefined,

          employees:
            this.employees && this.employees.length > 0
              ? this.employees
              : undefined,

          employeesCloseASale:
            this.employeesCloseASale && this.employeesCloseASale.length > 0
              ? this.employeesCloseASale
              : undefined,

          itemPerPage: this.itemPerPage,

          currentPage: this.currentPage,

          searchKey:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,

          searchType:
            this.searchKey && this.searchKey.length > 0
              ? this.searchType
              : undefined,

          savedSearchId: this.savedSearchId ? this.savedSearchId : undefined,

          created_date_from: this.createdDate ? this.createdDate[0] : undefined,

          created_date_to: this.createdDate ? this.createdDate[1] : undefined,

          recorded_date_from: this.recordedDate
            ? this.recordedDate[0]
            : undefined,

          recorded_date_to: this.recordedDate
            ? this.recordedDate[1]
            : undefined,

          debt_customer_from:
            this.debtFilter.from !== null ? this.debtFilter.from : undefined,
          debt_customer_to:
            this.debtFilter.to !== null ? this.debtFilter.to : undefined,

          sources:
            this.sources && this.sources.length > 0 ? this.sources : undefined,

          from_delivered_date: this.deliveredDates
            ? this.deliveredDates[0]
            : undefined,

          to_delivered_date: this.deliveredDates
            ? this.deliveredDates[1]
            : undefined,

          from_export_date: this.exportDates ? this.exportDates[0] : undefined,

          to_export_date: this.exportDates ? this.exportDates[1] : undefined
        }
      };

      if (
        currentRoute.name === targetRoute.name &&
        JSON.stringify(currentRoute.query) === JSON.stringify(targetRoute.query)
      ) {
        // Do nothing if already on the same route with the same query params
        return;
      }

      // Otherwise, navigate to the new route with query params
      await this.$router.push(targetRoute);
    },

    updateFilterProperty(data) {
      updateFilterProperty(this, data.filterProperty, data.value);
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    closeDrawer(val) {
      this.isDrawerVisible = val;
    },

    openDrawerMoreFilter() {
      this.isDrawerVisible = !this.isDrawerVisible;
    },

    handleSaveMoreFilter(value) {
      for (let item in value) {
        updateFilterProperty(this, item, value[item]);
      }
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    handleIncompleteMe() {
      this.tabs = "incomplete_me";
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    handleIncompleteBranch() {
      this.tabs = "incomplete_branch";
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  },

  watch: {
    "$route.query"(val) {
      this.getOrders(val);
    }

    // incomplete(val) {
    //   if (val) {
    //     this.currentPage = 1;
    //     updateFilterProperty(this, "tabs", "all");
    //     this.pushRouter();
    //   } else {
    //     this.currentPage = 1;
    //     this.pushRouter();
    //   }
    // }
  }
};
</script>
