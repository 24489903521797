<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="250"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-3"
        color="primary"
        depressed
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      >
        Lưu bộ lọc
      </v-btn>
    </template>

    <v-card>
      <div class="pa-4">
        <v-radio-group v-model="saveOption" hide-details>
          <v-radio label="Lưu bộ lọc mới" value="NEW_FILTER"></v-radio>

          <tp-text-field
            v-if="saveOption === 'NEW_FILTER'"
            v-model="filterName"
            class="mb-2"
            dense
            hide-details
            placeholder="Nhập tên bộ lọc mới"
          ></tp-text-field>

          <div v-if="savedFilters.length">
            <v-radio
              label="Lưu vào bộ lọc đã có"
              value="UPDATE_FILTER"
            ></v-radio>

            <tp-select
              v-if="saveOption === 'UPDATE_FILTER'"
              v-model="selectedFilterId"
              hide-details="auto"
              :items="savedFilters"
              item-text="filter_name"
              item-value="id"
              placeholder="Chọn tên bộ lọc"
              v-bind="$attrs"
            ></tp-select>
          </div>
        </v-radio-group>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false">
          Thoát
        </v-btn>
        <v-btn
          v-if="saveOption === 'NEW_FILTER'"
          color="primary"
          :disabled="isDisableSaveButton"
          text
          @click="handleCreateFilter"
        >
          Lưu
        </v-btn>

        <v-btn
          v-if="saveOption === 'UPDATE_FILTER'"
          color="primary"
          :disabled="isDisableSaveButton"
          text
          @click="handleUpdateFilter"
        >
          Lưu
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    saveOption: "NEW_FILTER",
    selectedFilterId: null,
    fav: true,
    filterName: "",
    menu: false,
    message: false,
    hints: true
  }),
  props: {
    disabled: {
      default: false
    }
  },
  computed: {
    savedFilters() {
      return this.$store.getters["ORDER/savedFilter"];
    },

    isDisableSaveButton() {
      if (this.saveOption === "NEW_FILTER") {
        return Boolean(!this.filterName.length);
      } else {
        return Boolean(!this.selectedFilterId);
      }
    }
  },
  methods: {
    getFilteredQueryData() {
      const query = this.$route.query;

      const filteredQuery = {};

      for (let item in query) {
        if (item === "deliveryDatesType" && query[item]) {
          // If deliveryDatesType exists and has a value, only keep it and remove delivery_date_from and delivery_date_to
          filteredQuery[item] = query[item];
          delete filteredQuery["delivery_date_from"];
          delete filteredQuery["delivery_date_to"];
        } else if (
          item !== "itemPerPage" &&
          item !== "currentPage" &&
          item !== "tabs" &&
          item !== "savedSearchId"
        ) {
          // Keep all other query parameters except for itemPerPage, currentPage, tabs, and savedSearchId
          filteredQuery[item] = query[item];
        }
      }
      return filteredQuery;
    },

    async handleCreateFilter() {
      const payload = {
        filter_name: this.filterName,
        filter_data: this.getFilteredQueryData()
      };

      await this.$store.dispatch("ORDER/createFilter", payload);

      // update data in UI
      this.$store.dispatch("ORDER/getFilters");

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Lưu thành công bộ lọc"
        }
      });

      this.menu = false;
    },

    async handleUpdateFilter() {
      const payload = {
        id: this.selectedFilterId,
        filter_data: this.getFilteredQueryData()
      };

      await this.$store.dispatch("ORDER/updateFilter", payload);

      // update data in UI
      this.$store.dispatch("ORDER/getFilters");

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Cập nhật thành công bộ lọc"
        }
      });

      this.menu = false;
    }
  },

  watch: {
    saveOption(value) {
      if (value === "NEW_FILTER") {
        this.selectedFilterId = null;
      }

      if (value === "UPDATE_FILTER") {
        this.filterName = "";
      }
    },

    menu(value) {
      if (value) return;
      this.selectedFilterId = null;
      this.filterName = "";
      this.saveOption = "NEW_FILTER";
    }
  }
};
</script>
