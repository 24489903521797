<template>
  <date-picker
    v-model="internalValue"
    format="DD/MM/YYYY HH:mm"
    :open.sync="isVisiblePanel"
    type="datetime"
    range
    :shortcuts="shortcuts"
    value-type="YYYY-MM-DD HH:mm"
    v-bind="$attrs"
    @clear="handleClearInput"
    @close="handleClosePanel"
    @change="handleChange"
  ></date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { DatePicker },
  props: {
    value: {
      type: Array
    }
  },

  data() {
    return {
      dateTimeRange: [new Date(), new Date()],
      internalValue: this.value,
      isVisiblePanel: false,
      shortcuts: [
        {
          text: "Đã quá hạn",
          onClick: () => {
            this.$emit("onSelectShortcut", "OUT_DATE");

            const today = new Date();
            return [today, today];
          }
        },
        {
          text: "Sắp đến hẹn (Dưới 2h)",
          onClick: () => {
            this.$emit("onSelectShortcut", "COMMING_SOON");

            const today = new Date();
            const MILLISECONDS_PER_TWO_HOURS = 2 * 60 * 60 * 1000;
            const afterTwoHours = new Date(
              today.getTime() - MILLISECONDS_PER_TWO_HOURS
            );

            return [afterTwoHours, today];
          }
        },
        {
          text: "Hôm nay",
          onClick: () => {
            this.$emit("onSelectShortcut", "TODAY");

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return [
              new Date(today.setHours(0, 0, 0)),
              new Date(today.setHours(23, 59, 59))
            ];
          }
        },
        {
          text: "Ngày mai",
          onClick: () => {
            this.$emit("onSelectShortcut", "TOMORROW");

            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            return [
              new Date(tomorrow.setHours(0, 0, 0)),
              new Date(tomorrow.setHours(23, 59, 59))
            ];
          }
        }
      ],
      selectedShortcut: ""
    };
  },

  methods: {
    handleClearInput() {
      this.$emit("input", []);
    },

    handleClosePanel() {
      if (this.selectedShortcut) this.$emit("onSelectShortcut", undefined);

      if (JSON.stringify(this.value) !== JSON.stringify(this.internalValue)) {
        this.$emit("input", this.internalValue);
      }
    },

    handleChange(value, type) {
      this.selectedShortcut = type;
    }
  },

  watch: {
    value: {
      handler() {
        this.internalValue = this.value;
      },
      immediate: true
    }
  }
};
</script>

<style>
.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 165px;
}

.mx-datepicker-sidebar {
  overflow: unset;
}
</style>
