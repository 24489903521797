<template>
  <v-menu bottom :close-on-content-click="false" offset-y>
    <!-- {{ items }} -->
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bordered
        class="z-1 select-filter-badge"
        :content="selectedLength"
        overlap
        :value="selectedLength"
      >
        <v-text-field
          append-icon="mdi-menu-down"
          dense
          :class="customClass"
          hide-details
          outlined
          :placeholder="customPlaceholder"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </v-badge>
    </template>

    <v-card>
      <div class="pa-4 d-flex">
        <div class="flex-1">
          <div class="mb-2 text-body-1 font-weight-medium">
            Trạng thái thanh toán
          </div>

          <div
            v-for="item in LIST_ORDER_PAYMENT_STATUS"
            :key="item.value"
            class="mb-2"
          >
            <v-checkbox
              v-model="computedPaymentStatus"
              dense
              hide-details
              :label="item.text"
              :value="item.value"
            ></v-checkbox>
          </div>
        </div>

        <v-divider vertical class="mx-5"></v-divider>

        <div class="flex-1">
          <div class="mb-2 text-body-1 font-weight-medium">
            Trạng thái giao hàng
          </div>

          <div v-for="item in LIST_STATUS_SHIPPING" :key="item.id" class="mb-2">
            <v-checkbox
              v-model="computedShippingStatus"
              dense
              hide-details
              :label="item.text"
              :value="item.id"
            ></v-checkbox>
          </div>
        </div>

        <v-divider vertical class="mx-5"></v-divider>

        <div class="flex-1">
          <div class="mb-2 text-body-1 font-weight-medium">
            Trạng thái đơn hàng
          </div>

          <div v-for="item in LIST_STATUS_ORDER" :key="item.id" class="mb-2">
            <v-checkbox
              v-model="computedOrderStatus"
              dense
              hide-details
              :label="item.text"
              :value="item.id"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import {
  LIST_ORDER_PAYMENT_STATUS,
  LIST_STATUS_SHIPPING,
  LIST_STATUS_ORDER
} from "../../../constant";
export default {
  props: {
    customClass: {
      default: ""
    },
    customPlaceholder: {
      default: ""
    },
    paymentStatus: {
      required: true
    },
    shippingStatus: {
      required: true
    },
    orderStatus: {
      required: true
    }
  },

  data() {
    return {
      LIST_ORDER_PAYMENT_STATUS: LIST_ORDER_PAYMENT_STATUS,
      LIST_STATUS_ORDER: LIST_STATUS_ORDER,
      LIST_STATUS_SHIPPING: LIST_STATUS_SHIPPING
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },
    selectedLength() {
      return (
        this.computedPaymentStatus.length +
        this.computedShippingStatus.length +
        this.computedOrderStatus.length
      );
    },

    computedPaymentStatus: {
      get() {
        return this.paymentStatus;
      },
      set(val) {
        this.$emit("update:paymentStatus", val);
      }
    },

    computedShippingStatus: {
      get() {
        return this.shippingStatus;
      },
      set(val) {
        this.$emit("update:shippingStatus", val);
      }
    },

    computedOrderStatus: {
      get() {
        return this.orderStatus;
      },
      set(val) {
        this.$emit("update:orderStatus", val);
      }
    }
  },

  methods: {
    onChangeValue(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style scoped>
.z-1 {
  z-index: 1;
}
.select-filter-badge {
  flex: 1;
}
</style>
