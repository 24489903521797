<template>
  <v-navigation-drawer
    v-model="internalVisible"
    class="custom-drawer"
    fixed
    right
    temporary
    width="350"
  >
    <div class="pa-4 d-flex justify-space-between">
      <div class="text-h6 font-weight-bold">Bộ lọc</div>

      <v-btn icon @click="internalVisible = false" small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-divider></v-divider>

    <div class="pa-4">
      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Kênh đặt hàng</div>

        <tp-autocomplete
          v-model="internalOrderChannels"
          chips
          clearable
          deletable-chips
          hide-details="auto"
          :items="LIST_ORDER_CHANNELS"
          item-text="name"
          item-value="id"
          multiple
          placeholder="Chọn kênh đặt hàng"
          small-chips
          validate="required"
        ></tp-autocomplete>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Nguồn đặt hàng</div>

        <tp-autocomplete
          v-model="internalSources"
          chips
          clearable
          deletable-chips
          hide-details="auto"
          :items="LIST_ORDER_SOURCES"
          item-text="label"
          item-value="value"
          multiple
          placeholder="Chọn nguồn đặt hàng"
          small-chips
          validate="required"
        ></tp-autocomplete>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Khách còn nợ</div>

        <select-range-price-filter
          v-model="internalDebtFilter"
          customPlaceholder="Chọn khoảng tiền"
          :items="debtValueList"
        ></select-range-price-filter>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Thương hiệu bán</div>

        <tp-autocomplete
          v-model="internalSites"
          chips
          clearable
          deletable-chips
          hide-details="auto"
          :items="ARRAY_LIST_MODEL_SITES"
          item-text="text"
          item-value="id"
          multiple
          placeholder="Chọn thương hiệu bán"
          small-chips
          validate="required"
        ></tp-autocomplete>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Nhân viên phụ trách</div>

        <tp-autocomplete
          v-model="internalEmployees"
          chips
          clearable
          deletable-chips
          hide-details="auto"
          :items="employees"
          item-text="name"
          item-value="id"
          multiple
          placeholder="Chọn nhân viên phụ trách"
          prepend-inner-icon="mdi-magnify"
          small-chips
        ></tp-autocomplete>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Nhân viên chốt đơn</div>

        <tp-autocomplete
          v-model="internalEmployeesCloseASale"
          chips
          clearable
          deletable-chips
          hide-details="auto"
          :items="saleEmployees"
          item-text="name"
          item-value="id"
          multiple
          placeholder="Chọn nhân viên chốt đơn"
          prepend-inner-icon="mdi-magnify"
          small-chips
        ></tp-autocomplete>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Ngày tạo đơn</div>

        <tp-input-time-range
          v-model="internalCreatedDate"
          custom-class="order-filter-item text-body-1 rounded"
          custom-placeholder="Ngày tạo đơn"
        ></tp-input-time-range>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Ngày ghi nhận đơn</div>

        <tp-input-time-range
          v-model="internalRecordedDate"
          custom-class="order-filter-item text-body-1 rounded"
          custom-placeholder="Ngày ghi nhận đơn"
        ></tp-input-time-range>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Ngày giao hàng</div>

        <tp-input-time-range
          v-model="internalDeliveredDates"
          custom-class="order-filter-item text-body-1 rounded"
          custom-placeholder="Chọn ngày giao hàng"
        ></tp-input-time-range>
      </div>

      <div class="mb-3">
        <div class="text-body-1 font-weight-bold">Ngày xuất kho</div>

        <tp-input-time-range
          v-model="internalExportDates"
          custom-class="order-filter-item text-body-1 rounded"
          custom-placeholder="Chọn ngày xuất kho"
        ></tp-input-time-range>
      </div>
    </div>

    <template v-slot:append>
      <v-divider></v-divider>

      <div class="pa-4 d-flex justify-end">
        <v-btn
          depressed
          outlined
          color="red"
          class="mr-2"
          @click="handleResetFilter"
        >
          Xóa bộ lọc
        </v-btn>

        <v-btn depressed color="primary" @click="handleSaveFilter">
          Lọc
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { LIST_ORDER_CHANNELS, LIST_ORDER_SOURCES } from "../../../constant";
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";
import SelectRangePriceFilter from "./SelectRangePriceFilter.vue";

export default {
  components: { SelectRangePriceFilter },
  props: {
    visible: {
      default: false,
      required: true
    },
    debtValueList: {
      type: Array
    },
    orderChannels: {
      type: Array
    },
    sites: {
      type: Array
    },
    employeesFilter: {
      type: Array
    },
    employeesCloseASale: {
      type: Array
    },
    createdDate: {
      type: Array
    },
    recordedDate: {
      type: Array
    },
    debtFilter: {
      type: Object
    },
    sources: {
      type: Array
    },
    deliveredDates: {
      type: Array
    },
    exportDates: {
      type: Array
    }
  },

  computed: {
    internalVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("closeDrawer", val);
      }
    },
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    saleEmployees() {
      return this.employees.filter(i => i.staff_roles.includes("SALES"));
    }
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES,
      LIST_ORDER_CHANNELS: LIST_ORDER_CHANNELS,
      LIST_ORDER_SOURCES: LIST_ORDER_SOURCES,
      drawer: null,
      deliveryDates: [],
      internalCreatedDate: [],
      internalRecordedDate: [],
      internalOrderChannels: [],
      internalSites: [],
      internalEmployees: [],
      internalEmployeesCloseASale: [],
      internalDebtFilter: {
        debtFrom: null,
        debtTo: null
      },
      internalSources: [],
      internalDeliveredDates: [],
      internalExportDates: [],

      defaultFilterData: {
        searchKey: undefined,
        searchType: 0,
        branches: [],
        deliveryDates: [],
        orderStatus: [],
        assignedMe: false,
        paymentStatus: [],
        shippingStatus: [],
        orderChannels: [],
        sites: [],
        employees: [],
        employeesCloseASale: [],
        savedSearchId: 0,
        deliveryDatesType: "",
        createdDate: [],
        recordedDate: [],
        debtFilter: {
          debtFrom: null,
          debtTo: null
        },
        sources: [],
        deliveredDates: [],
        exportDates: []
      }
    };
  },

  methods: {
    handleSaveFilter() {
      const filter = {
        orderChannels: this.internalOrderChannels,
        sites: this.internalSites,
        employees: this.internalEmployees,
        employeesCloseASale: this.internalEmployeesCloseASale,
        createdDate: this.internalCreatedDate,
        recordedDate: this.internalRecordedDate,
        debtFilter: this.internalDebtFilter,
        sources: this.internalSources,
        deliveredDates: this.internalDeliveredDates,
        exportDates: this.internalExportDates
      };

      this.$emit("onSubmit", filter);
    },

    handleResetFilter() {
      const defaultFilter = {
        searchKey: undefined,
        searchType: 0,
        branches: [],
        deliveryDates: [],
        orderStatus: [],
        assignedMe: false,
        paymentStatus: [],
        shippingStatus: [],
        orderChannels: [],
        sites: [],
        employees: [],
        employeesCloseASale: [],
        savedSearchId: 0,
        deliveryDatesType: "",
        createdDate: [],
        recordedDate: [],
        debtFilter: {
          from: null,
          to: null
        },
        sources: [],
        deliveredDates: [],
        exportDates: []
      };

      this.$emit("onSubmit", defaultFilter);
    },

    setDefaultValue() {
      this.internalCreatedDate = this.createdDate;
      this.internalRecordedDate = this.recordedDate;
      this.internalOrderChannels = this.orderChannels;
      this.internalSites = this.sites;
      this.internalEmployees = this.employeesFilter;
      this.internalEmployeesCloseASale = this.employeesCloseASale;
      this.internalDebtFilter = this.debtFilter;
      this.internalSources = this.sources;
      this.internalDeliveredDates = this.deliveredDates;
      this.internalExportDates = this.exportDates;
    }
  },

  watch: {
    "$route.query": {
      handler() {
        this.setDefaultValue();
      },
      immediate: true
    },
    visible() {
      this.setDefaultValue();
    }
  }
};
</script>

<style>
.custom-drawer {
  height: 100px;
}
</style>
