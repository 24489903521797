<template>
  <v-card v-if="hasTags" flat class="pa-1">
    <v-chip
      v-if="assignedMe"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'assignedMe',
          value: false
        })
      "
    >
      Phụ trách bởi tôi
    </v-chip>

    <v-chip
      v-if="filterBranches.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'branches',
          value: []
        })
      "
    >
      Chi nhánh phụ trách:
      {{ getNameOfSelectedValue(branches, filterBranches, "name", "id") }}
    </v-chip>

    <v-chip
      v-if="deliveryDates.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'deliveryDates',
          value: []
        })
      "
    >
      Ngày hẹn giao: {{ formatedImportDate }}
    </v-chip>

    <v-chip
      v-if="createdDate.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'createdDate',
          value: []
        })
      "
    >
      Ngày tạo đơn: {{ formatCreatedDate }}
    </v-chip>

    <v-chip
      v-if="recordedDate.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'recordedDate',
          value: []
        })
      "
    >
      Ngày ghi nhận đơn: {{ formatRecordedDate }}
    </v-chip>

    <v-chip
      v-if="deliveredDates.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'deliveredDates',
          value: []
        })
      "
    >
      Ngày giao hàng: {{ formatDeliveredDates }}
    </v-chip>

    <v-chip
      v-if="exportDates.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'exportDates',
          value: []
        })
      "
    >
      Ngày xuất kho: {{ formatExportDates }}
    </v-chip>

    <v-chip
      v-if="paymentStatus.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'paymentStatus',
          value: ''
        })
      "
    >
      Trạng thái thanh toán:
      {{
        getNameOfSelectedValue(
          LIST_ORDER_PAYMENT_STATUS,
          paymentStatus,
          "text",
          "value"
        )
      }}
    </v-chip>

    <v-chip
      v-if="shippingStatus.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'shippingStatus',
          value: []
        })
      "
    >
      Trạng thái giao hàng:
      {{
        getNameOfSelectedValue(
          LIST_STATUS_SHIPPING,
          shippingStatus,
          "text",
          "id"
        )
      }}
    </v-chip>

    <v-chip
      v-if="orderStatus.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'orderStatus',
          value: []
        })
      "
    >
      Trạng thái giao hàng:
      {{ getNameOfSelectedValue(LIST_STATUS_ORDER, orderStatus, "text", "id") }}
    </v-chip>

    <v-chip
      v-if="orderChannels.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'orderChannels',
          value: []
        })
      "
    >
      Kênh đặt hàng:
      {{
        getNameOfSelectedValue(LIST_ORDER_CHANNELS, orderChannels, "name", "id")
      }}
    </v-chip>

    <v-chip
      v-if="sources.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'sources',
          value: []
        })
      "
    >
      Nguồn đặt hàng:
      {{
        getNameOfSelectedValue(LIST_ORDER_SOURCES, sources, "label", "value")
      }}
    </v-chip>

    <v-chip
      v-if="!(debtFilter.from === null && debtFilter.to === null)"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'debtFilter',
          value: { from: null, to: null }
        })
      "
    >
      Công nợ:
      {{ getDebtValueByValue }}
    </v-chip>

    <v-chip
      v-if="sites.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'sites',
          value: []
        })
      "
    >
      Thương hiệu bán:
      {{ getNameOfSelectedValue(ARRAY_LIST_MODEL_SITES, sites, "text", "id") }}
    </v-chip>

    <v-chip
      v-if="employeesFilter.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'employees',
          value: []
        })
      "
    >
      Nhân viên phụ trách:
      {{ getNameOfSelectedValue(employees, employeesFilter, "name", "id") }}
    </v-chip>

    <v-chip
      v-if="employeesCloseASale.length"
      class="ma-2"
      close
      @click:close="
        $emit('updateFilterProperty', {
          filterProperty: 'employeesCloseASale',
          value: []
        })
      "
    >
      Nhân viên chốt đơn:
      {{ getNameOfSelectedValue(employees, employeesCloseASale, "name", "id") }}
    </v-chip>
  </v-card>
</template>

<script>
import {
  LIST_ORDER_PAYMENT_STATUS,
  LIST_STATUS_SHIPPING,
  LIST_ORDER_CHANNELS,
  LIST_STATUS_ORDER,
  LIST_ORDER_SOURCES
} from "../../../constant";

import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";

import moment from "moment";

export default {
  props: {
    assignedMe: {
      type: Boolean
    },
    filterBranches: {
      type: Array
    },
    debtFilter: {
      type: Object,
      default: () => ({
        from: null,
        to: null
      })
    },
    debtValueList: {
      type: Array
    },
    deliveryDates: {
      type: Array
    },
    paymentStatus: {
      type: Array
    },
    shippingStatus: {
      type: Array
    },
    orderChannels: {
      type: Array
    },
    orderStatus: {
      type: Array
    },
    sites: {
      type: Array
    },
    sources: {
      type: Array
    },
    employeesFilter: {
      type: Array
    },
    employeesCloseASale: {
      type: Array
    },
    createdDate: {
      type: Array
    },
    recordedDate: {
      type: Array
    },
    deliveredDates: {
      type: Array
    },
    exportDates: {
      type: Array
    }
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES,
      LIST_ORDER_CHANNELS: LIST_ORDER_CHANNELS,
      LIST_ORDER_PAYMENT_STATUS: LIST_ORDER_PAYMENT_STATUS,
      LIST_ORDER_SOURCES: LIST_ORDER_SOURCES,
      LIST_STATUS_ORDER: LIST_STATUS_ORDER,
      LIST_STATUS_SHIPPING: LIST_STATUS_SHIPPING,
      MILLISECONDS_PER_HOUR: 1000 * 60 * 60
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    formatedImportDate() {
      const query = this.$route.query;

      if (query.deliveryDatesType === "OUT_DATE") {
        return `Đã quá hạn`;
      }

      if (query.deliveryDatesType === "COMMING_SOON") {
        return `Sắp đến hẹn (dưới 2h)`;
      }

      if (query.deliveryDatesType === "TODAY") {
        return `Hôm nay (${moment().format("DD/MM/yyyy")})`;
      }

      if (query.deliveryDatesType === "TOMORROW") {
        return `Ngày mai (${moment()
          .add(1, "days")
          .format("DD/MM/yyyy")})`;
      }

      return `Từ ${moment(this.deliveryDates[0]).format(
        "DD/MM/yyyy HH:mm"
      )} đến ${moment(this.deliveryDates[1]).format("DD/MM/yyyy HH:mm")}`;
    },

    formatCreatedDate() {
      return `từ ${moment(this.createdDate[0]).format(
        "DD/MM/yyyy"
      )} đến ${moment(this.createdDate[1]).format("DD/MM/yyyy")}`;
    },

    formatExportDates() {
      return `từ ${moment(this.exportDates[0]).format(
        "DD/MM/yyyy"
      )} đến ${moment(this.exportDates[1]).format("DD/MM/yyyy")}`;
    },

    formatRecordedDate() {
      return `từ ${moment(this.recordedDate[0]).format(
        "DD/MM/yyyy"
      )} đến ${moment(this.recordedDate[1]).format("DD/MM/yyyy")}`;
    },

    formatDeliveredDates() {
      return `từ ${moment(this.deliveredDates[0]).format(
        "DD/MM/yyyy"
      )} đến ${moment(this.deliveredDates[1]).format("DD/MM/yyyy")}`;
    },

    getDebtValueByValue() {
      const foundValue = this.debtValueList.find(debtValue => {
        const { from, to } = debtValue.value;
        return from === this.debtFilter.from && to === this.debtFilter.to;
      });

      const fromValue = this.debtFilter.from
        ? `${this.formatCurrency(this.debtFilter.from)}`
        : "0đ";
      const toValue = this.debtFilter.to
        ? `${this.formatCurrency(this.debtFilter.to)}`
        : "0đ";

      if (foundValue) {
        return foundValue.text;
      }
      return `Từ ${fromValue} đến ${toValue}`;
    },

    hasTags() {
      return !(
        !this.filterBranches.length &&
        !this.assignedMe &&
        !this.deliveryDates.length &&
        !this.paymentStatus.length &&
        !this.shippingStatus.length &&
        !this.orderChannels.length &&
        !this.orderStatus.length &&
        !this.sites.length &&
        !this.employeesFilter.length &&
        !this.employeesCloseASale.length &&
        !this.createdDate.length &&
        !this.recordedDate.length &&
        this.debtFilter.from === null &&
        this.debtFilter.to === null &&
        !this.sources.length &&
        !this.deliveredDates.length &&
        !this.exportDates.length
      );
    }
  },

  methods: {
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });
      return formatter.format(value);
    },

    getNameOfSelectedValue(array, selectedValue, name, uniqueField) {
      return array
        .filter(item => selectedValue.includes(item[uniqueField]))
        .map(item => item[name])
        .join(", ");
    }
  }
};
</script>
