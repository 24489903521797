<template>
  <div class="d-flex align-center justify-space-between">
    <v-tabs v-model="activePage" background-color="grey lighten-3">
      <v-tab class="font-weight-bold" href="#OrderPage">Đơn hàng</v-tab>

      <v-tab
        :to="{
          name: 'transaction_sale-receipts'
        }"
        class="font-weight-bold"
        href="#SaleReceipt"
      >
        Hoá đơn bán
      </v-tab>
    </v-tabs>

    <div class="d-flex">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="orderStatusRequest.value === 'loading-exportOrderFile'"
            class="mr-3"
            color="primary"
            dark
            depressed
            outlined
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-file-download-outline</v-icon>
            Xuất file
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in EXPORT_TYPE"
            :key="index"
            @click="$emit('exportReportFile', item.type)"
          >
            {{ item.title }}
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn color="primary" depressed @click="goToOrderPage()">
        <v-icon left>mdi-plus</v-icon>
        Đặt hàng
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      EXPORT_TYPE: [
        { title: "Mặc định", type: "DEFAULT" },
        { title: "HR", type: "HR  " },
        { title: "Kế toán", type: "ACCOUNTING" }
      ],
      activePage: "OrderPage"
    };
  },
  computed: {
    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },

    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },

    saleEmployees() {
      return this.employees.filter(i => i.staff_roles.includes("SALES"));
    },

    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    }
  },

  methods: {
    hasBranchId(branchId) {
      return this.branches.some(i => i.id === branchId);
    },

    isSaleEmployee(employeeId) {
      return this.saleEmployees.some(i => i.id === employeeId);
    },

    async goToOrderPage() {
      const emptyOrder = {
        address_id: null,
        assigned_to_id: this.isSaleEmployee(this.user.id) ? this.user.id : null,
        branch_id: this.hasBranchId(this.user.branch_id)
          ? this.user.branch_id
          : null,
        branch_nhan_id: this.hasBranchId(this.user.branch_id)
          ? this.user.branch_id
          : null,
        branch_nhan: null,
        closing_user_id: this.isSaleEmployee(this.user.id)
          ? this.user.id
          : null,
        code: `Tạo mới`,
        count_cod_transaction_waiting: 0,
        count_paid: 0,
        count_transaction_waiting: 0,
        customer_id: null,
        customer: null,
        details: [],
        discount: null,
        handles: [],
        id: null,
        note: null,
        order_channel: 2,
        order_type: "ORDER",
        payment_fee: null,
        payment_info: null,
        pre_paid_amount: null,
        promos: [],
        promotion_details: null,
        promotion_id: null, // promotion for invoice
        received_address: null,
        received_method: 2,
        shipping_est_date: null,
        shipping_fee: null,
        shipping_partner: null,
        status: 1,
        sum_cod_transaction_waiting: 0,
        sum_paid: 0,
        sum_transaction_waiting: 0,
        site: "THINKPRO",
        value: null,
        timeline: [
          {
            status: 1,
            is_complete: true,
            datetime: null,
            label: "Chờ xác nhận",
            description: ""
          },
          {
            status: 2,
            is_complete: false,
            datetime: null,
            label: "Đã xác nhận",
            description: ""
          },
          {
            status: 3,
            is_complete: false,
            datetime: null,
            label: "Đang xử lý",
            description: ""
          },
          {
            status: 4,
            is_complete: false,
            datetime: null,
            label: "Hoàn thành",
            description: ""
          }
        ]
      };

      await this.$store.dispatch("SALE/addSaleTabItem", emptyOrder);

      // Set active tab
      await this.$store.dispatch(
        "SALE/setSaleTabActiveIndex",
        this.saleTabs.length - 1
      );

      // Reset order and order processing
      await this.$store.dispatch("ORDER/setOrder", emptyOrder);
      await this.$store.dispatch("ORDER/resetOrderProcessing");
      this.$store.dispatch("ORDER/resetOrderPaymentTransactions");
      this.$store.dispatch("ORDER/resetOrderExportTransactions");

      // Push route
      await this.$router.push({ name: "sale" });
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
