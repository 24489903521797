<template>
  <div>
    <v-menu bottom :close-on-content-click="false" offset-y max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          append-icon="mdi-menu-down"
          dense
          class="text-body-1"
          :class="customClass"
          hide-details
          outlined
          placeholder="qwascvasv"
          readonly
          :value="valueForTextField"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <v-card class="pa-4">
        <v-radio-group class="mt-0" v-model="type" dense hide-details>
          <div class="d_flex align-center mb-3">
            <v-radio
              class="mb-0"
              :value="1"
              @change="$emit('change', specificValue)"
            ></v-radio>

            <v-select
              v-model="specificValue"
              append-icon="mdi-menu-swap"
              class="text-body-1 mt-0"
              dense
              hide-details
              :items="items"
              :menu-props="{ nudgeTop: '-27px' }"
              item-text="text"
              item-value="value"
              placeholder="Chọn giá trị"
              @change="updateSpecificValue($event)"
            ></v-select>
          </div>

          <div class="d_flex align-center">
            <v-radio
              class="mb-0"
              :value="2"
              @change="$emit('change', customValue)"
            ></v-radio>

            <tp-input-range-price
              :value="customValue"
              @change="updateCustomValue($event)"
            ></tp-input-range-price>
          </div>
        </v-radio-group>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    disabledNegativeNumber: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ""
    },
    customPlaceholder: {
      type: String,
      default: "Chọn giá trị"
    },
    items: {
      type: Array,
      default: () => {
        return [
          {
            id: 1,
            name: "Tất cả",
            value: { from: 0, to: 0 }
          }
        ];
      }
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      customValue: {
        from: null,
        to: null
      },
      specificValue: null,
      type: 1
    };
  },

  watch: {
    value: {
      handler(val) {
        if (val) {
          // Check value is specific value
          this.checkSpecificValue(val);
        }
      },
      immediate: true
    }
  },

  computed: {
    valueForTextField() {
      if (this.type === 1) {
        return this.specificValue
          ? this.getDebtValueByValue(this.specificValue).text
          : this.customPlaceholder;
      } else {
        const fromValue = this.customValue.from
          ? `${this.formatCurrency(this.customValue.from)}`
          : "0đ";
        const toValue = this.customValue.to
          ? `${this.formatCurrency(this.customValue.to)}`
          : "0đ";
        return `Từ ${fromValue} đến ${toValue}`;
      }
    }
  },

  methods: {
    checkSpecificValue(val) {
      let check = false;

      this.items.forEach(item => {
        if (item.value.from === val.from && item.value.to === val.to) {
          this.type = 1;
          this.specificValue = item.value;
          check = true;
          return false;
        }
      });

      if (!check) {
        this.type = 2;
        this.customValue = val;
      }
    },

    formatCurrency(value) {
      const formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });
      return formatter.format(value);
    },

    getDebtValueByValue(value) {
      return this.items.find(debtValue => {
        const { from, to } = debtValue.value;
        return from === value.from && to === value.to;
      });
    },
    updateCustomValue(val) {
      this.customValue = val;
      this.type = 2;
      this.$emit("input", val);
    },
    updateSpecificValue(val) {
      this.type = 1;
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-hover {
  cursor: pointer;
}
.input-date--customize {
  font-size: 13px !important;
}
</style>
