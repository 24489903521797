<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="primary">mdi-cog</v-icon>
      </v-btn>
    </template>

    <v-card class="pa-4" flat>
      <div>
        <!-- <div v-for="item in HEADERS_ORDER_TABLE" :key="item.value">
          {{ item.text }}
        </div> -->

        <v-checkbox
          v-for="item in Object.values(HEADERS_ORDER_TABLE_MAP)"
          v-model="internalSelectedColumn"
          class="mt-0"
          hide-details
          :key="item.value"
          :label="item.text"
          :value="item"
        ></v-checkbox>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { HEADERS_ORDER_TABLE_MAP } from "../../../constant";

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      menu: false,
      HEADERS_ORDER_TABLE_MAP: HEADERS_ORDER_TABLE_MAP
    };
  },

  created() {
    const settingColumn = localStorage.getItem("ORDER_COLUMN_SETTINGS");
    if (settingColumn) {
      this.internalSelectedColumn = JSON.parse(settingColumn);
      return;
    }

    this.internalSelectedColumn = Object.values(this.HEADERS_ORDER_TABLE_MAP);
  },

  computed: {
    internalSelectedColumn: {
      get() {
        return this.value;
      },
      set(value) {
        localStorage.setItem("ORDER_COLUMN_SETTINGS", JSON.stringify(value));

        this.$emit("input", value);
      }
    }
  }
};
</script>

<style></style>
