var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasTags)?_c('v-card',{staticClass:"pa-1",attrs:{"flat":""}},[(_vm.assignedMe)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'assignedMe',
        value: false
      })}}},[_vm._v(" Phụ trách bởi tôi ")]):_vm._e(),(_vm.filterBranches.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'branches',
        value: []
      })}}},[_vm._v(" Chi nhánh phụ trách: "+_vm._s(_vm.getNameOfSelectedValue(_vm.branches, _vm.filterBranches, "name", "id"))+" ")]):_vm._e(),(_vm.deliveryDates.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'deliveryDates',
        value: []
      })}}},[_vm._v(" Ngày hẹn giao: "+_vm._s(_vm.formatedImportDate)+" ")]):_vm._e(),(_vm.createdDate.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'createdDate',
        value: []
      })}}},[_vm._v(" Ngày tạo đơn: "+_vm._s(_vm.formatCreatedDate)+" ")]):_vm._e(),(_vm.recordedDate.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'recordedDate',
        value: []
      })}}},[_vm._v(" Ngày ghi nhận đơn: "+_vm._s(_vm.formatRecordedDate)+" ")]):_vm._e(),(_vm.deliveredDates.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'deliveredDates',
        value: []
      })}}},[_vm._v(" Ngày giao hàng: "+_vm._s(_vm.formatDeliveredDates)+" ")]):_vm._e(),(_vm.exportDates.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'exportDates',
        value: []
      })}}},[_vm._v(" Ngày xuất kho: "+_vm._s(_vm.formatExportDates)+" ")]):_vm._e(),(_vm.paymentStatus.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'paymentStatus',
        value: ''
      })}}},[_vm._v(" Trạng thái thanh toán: "+_vm._s(_vm.getNameOfSelectedValue( _vm.LIST_ORDER_PAYMENT_STATUS, _vm.paymentStatus, "text", "value" ))+" ")]):_vm._e(),(_vm.shippingStatus.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'shippingStatus',
        value: []
      })}}},[_vm._v(" Trạng thái giao hàng: "+_vm._s(_vm.getNameOfSelectedValue( _vm.LIST_STATUS_SHIPPING, _vm.shippingStatus, "text", "id" ))+" ")]):_vm._e(),(_vm.orderStatus.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'orderStatus',
        value: []
      })}}},[_vm._v(" Trạng thái giao hàng: "+_vm._s(_vm.getNameOfSelectedValue(_vm.LIST_STATUS_ORDER, _vm.orderStatus, "text", "id"))+" ")]):_vm._e(),(_vm.orderChannels.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'orderChannels',
        value: []
      })}}},[_vm._v(" Kênh đặt hàng: "+_vm._s(_vm.getNameOfSelectedValue(_vm.LIST_ORDER_CHANNELS, _vm.orderChannels, "name", "id"))+" ")]):_vm._e(),(_vm.sources.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'sources',
        value: []
      })}}},[_vm._v(" Nguồn đặt hàng: "+_vm._s(_vm.getNameOfSelectedValue(_vm.LIST_ORDER_SOURCES, _vm.sources, "label", "value"))+" ")]):_vm._e(),(!(_vm.debtFilter.from === null && _vm.debtFilter.to === null))?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'debtFilter',
        value: { from: null, to: null }
      })}}},[_vm._v(" Công nợ: "+_vm._s(_vm.getDebtValueByValue)+" ")]):_vm._e(),(_vm.sites.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'sites',
        value: []
      })}}},[_vm._v(" Thương hiệu bán: "+_vm._s(_vm.getNameOfSelectedValue(_vm.ARRAY_LIST_MODEL_SITES, _vm.sites, "text", "id"))+" ")]):_vm._e(),(_vm.employeesFilter.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'employees',
        value: []
      })}}},[_vm._v(" Nhân viên phụ trách: "+_vm._s(_vm.getNameOfSelectedValue(_vm.employees, _vm.employeesFilter, "name", "id"))+" ")]):_vm._e(),(_vm.employeesCloseASale.length)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.$emit('updateFilterProperty', {
        filterProperty: 'employeesCloseASale',
        value: []
      })}}},[_vm._v(" Nhân viên chốt đơn: "+_vm._s(_vm.getNameOfSelectedValue(_vm.employees, _vm.employeesCloseASale, "name", "id"))+" ")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }