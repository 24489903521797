<template>
  <div class="d-flex justify-space-between">
    <tp-btn-overflow
      btn-class="white rounded-lg"
      :items="showFilterList"
      :selected-value="selectedShowFilterId"
      @select="selectShowFilter($event)"
    ></tp-btn-overflow>

    <div class="action-row--col d-flex align-center">
      <div class="text-body-2">
        {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
        {{ paginationInfo.itemTotal }}
      </div>
      <v-pagination
        v-model="curPage"
        :length="paginationInfo.pageTotal"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedShowFilterId: {
      type: Number
    }
  },

  data() {
    return {
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["ORDER/paginationInfo"];
    }
  },

  methods: {
    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    }
  }
};
</script>
